import revive_payload_client_oMMlrBV5mk from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_FGQkD5WJQ0 from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vite@6.0.6_@types+no_guxqavkiva72uvjk565m5kdcjq/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import unhead_qRP2VBgCsF from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_z9ibpL9Oxa from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_qgdHfk7VMY from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ehcWZ0BWR9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_iGZvZ9JX9E from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8oCExFjJTY from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_dYXvRynsbE from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_wbozgmwwa2blqwhbx42kaoouzy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_HpZWMCBLbA from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.29.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_N2sbJYXWt3 from "/vercel/path0/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.7_magicast@0.3.5_next-auth@4.21.1_next@13.5.8_@babel+core@7.26.0_reac_pwckutjsjnye5ossarmdvotieu/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_6KIYIcr9fp from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_leYNsaxsUs from "/vercel/path0/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5_6atuu3ohoq2utnacoes4uwmdxu/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import vuetify_no_client_hints_0fqelOUyPV from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vite@6.0.6_@types+no_guxqavkiva72uvjk565m5kdcjq/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/.nuxt/pwa-icons-plugin.ts";
import pwa_client_02C4TZThhH from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.29.1_vite@6.0.6_@types+node@22.10.2_jiti@2.4.2__bizltvxy7oynj272jfgktea4ta/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import hotjar_pBWYs5JQbk from "/vercel/path0/plugins/hotjar.ts";
import octokit_client_D2Nkfi13sb from "/vercel/path0/plugins/octokit.client.ts";
import vue_datepicker_dyozMmv23j from "/vercel/path0/plugins/vue-datepicker.ts";
import vue_draggable_OKRqLUiGtd from "/vercel/path0/plugins/vue-draggable.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/vercel/path0/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_oMMlrBV5mk,
  vuetify_icons_FGQkD5WJQ0,
  unhead_qRP2VBgCsF,
  router_z9ibpL9Oxa,
  navigation_repaint_client_qgdHfk7VMY,
  check_outdated_build_client_ehcWZ0BWR9,
  chunk_reload_client_iGZvZ9JX9E,
  plugin_vue3_8oCExFjJTY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dYXvRynsbE,
  plugin_wy0B721ODc,
  plugin_client_HpZWMCBLbA,
  plugin_N2sbJYXWt3,
  plugin_6KIYIcr9fp,
  plugin_leYNsaxsUs,
  vuetify_no_client_hints_0fqelOUyPV,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_02C4TZThhH,
  hotjar_pBWYs5JQbk,
  octokit_client_D2Nkfi13sb,
  vue_datepicker_dyozMmv23j,
  vue_draggable_OKRqLUiGtd,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]